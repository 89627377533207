import { useCallback, useEffect, useRef, useState } from "react";
import { useOnScreen } from "@sequoiacap/client-ui";
import useInfiniteScroll from "react-infinite-scroll-hook";
import useTimeoutFn from "~/hooks/useTimeoutFn";

type InfiniteScrollProps = {
  className?: string;
  onLoadMore: () => void;
  hasNextPage: boolean;
  loading: boolean;
  loader: React.ReactNode;
  children?: React.ReactNode;
  endMessage?: React.ReactNode;
  disabled?: boolean;
  initialLoad?: boolean;
  hideContentWhileLoading?: boolean;
  validating?: boolean;
};

export const InfiniteScroll = ({
  className,
  onLoadMore,
  hasNextPage,
  loader,
  loading,
  children,
  endMessage = null,
  disabled = false,
  initialLoad = false,
  hideContentWhileLoading = false,
  validating = false,
}: InfiniteScrollProps) => {
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    rootMargin: "0px 0px 100px 0px",
    disabled,
  });

  // This prevents the infinite scroll from getting stuck.
  // When the screen is very tall (e.g. zoom out) and you scroll quickly to the bottom, the infinite scroll doesn't know the loader is visible yet not loading.
  // So we periodically check if the loader is visible and if it is, manually call onLoadMore.
  const [loaderContainerRef, setLoaderContainerRef] =
    useState<HTMLElement | null>(null);
  const isOnScreen = useOnScreen(loaderContainerRef);
  const [, , setTimeoutAgain] = useTimeoutFn(() => {
    if (isOnScreen && hasNextPage && !loading && !validating) {
      onLoadMore();
    }
    setTimeoutAgain();
  }, 500);

  // Hack to manually trigger onLoadMore. For some reason `useGetComments` keeps sending back
  // hasNextPage=true even when there are no more comments to load.
  useEffect(() => {
    if (initialLoad && !validating) {
      setTimeout(onLoadMore);
    }
  }, [validating, initialLoad, onLoadMore]);

  return (
    <div className={className}>
      {hideContentWhileLoading && loading ? null : children}
      {(loading || hasNextPage) && (
        <div ref={sentryRef} data-infinite-scroll-loader>
          <div ref={setLoaderContainerRef}>{loader}</div>
        </div>
      )}
      {!hasNextPage && endMessage}
    </div>
  );
};
